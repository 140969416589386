import { Button, DatePicker, Space } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import RefreshIcon from "../../assets/icons/RefreshIcon";
import Map from "../../components/NP/Dashboard/Map";
import SiteLocator from "../../components/NP/Dashboard/SiteLocator";
import TopBarLog from "../../components/NP/Dashboard/TopBarLog";
import IconButton from "../../components/common/IconButton";
import Loader from "../../components/common/Loader";
import AdminLayout from "../../layouts/AdminLayout";
import {
  getActiveAndInactiveMapAsync,
  getActiveAndInactiveTowerAsync,
  getDashboardLogAsync,
} from "../../store/features/NP/dashboard/dashboardAPI";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { DashboardParams } from "../../types/redux";
import { formatDate, previousDate } from "../../utils/time";

export default function Dashboard() {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.dashboard);
  const [refresh, setRefresh] = useState(false);
  // const handleDateChange: DatePickerProps["onChange"] = (date, dateString) => {
  //   console.log(date, dateString);
  // };

  const [today, setToday] = useState(true);
  const [date, setDate] = useState({
    from: "",
    to: "",
  });

  const removeRefresh = () => {
    setRefresh(false);
  };

  const handleRefresh = () => {
    dispatch(getActiveAndInactiveTowerAsync({}));
    dispatch(getActiveAndInactiveMapAsync({}));
    dispatch(
      getDashboardLogAsync({
        params: { today: previousDate(formatDate(new Date(), "YYYY-MM-DD")) },
      })
    );
    setRefresh(true);
    setDate({
      from: "",
      to: "",
    });
    setToday(true);
  };

  useEffect(() => {
    let params: DashboardParams = {};

    if (today) {
      params.today = previousDate(formatDate(new Date(), "YYYY-MM-DD"));
    } else {
      params.startDate = date.from ? previousDate(date.from) : "";
      params.endDate = date.to ? previousDate(date.to) : "";
    }

    dispatch(getDashboardLogAsync({ params }));
  }, [date.from, date.to, dispatch, today]);

  useEffect(() => {
    dispatch(getActiveAndInactiveTowerAsync({}));
    dispatch(getActiveAndInactiveMapAsync({}));
  }, [dispatch]);

  return (
    <AdminLayout
      title="Dashboard"
      headerTitle="Dashboard"
      headerChildren={
        <Space size="middle" className="flex-wrap">
          <Button
            className={today ? "" : "!border-accent"}
            type={today ? "primary" : "default"}
            onClick={() => {
              setDate({
                from: "",
                to: "",
              });
              setToday(true);
            }}
          >
            Today
          </Button>
          <DatePicker.RangePicker
            format="YYYY-MM-DD"
            // size={innerWidth > 768 ? "large" : "middle"}
            // style={{ width: innerWidth > 768 ? "270px" : "240px" }}
            className="date-range"
            // popupClassName="one-month-date-range"
            value={[
              date?.from ? dayjs(date?.from, "YYYY-MM-DD") : null,
              date?.to ? dayjs(date?.to, "YYYY-MM-DD") : null,
            ]}
            onChange={(val) => {
              setDate({
                from: val?.[0]?.format("YYYY-MM-DD") || "",
                to: val?.[1]?.format("YYYY-MM-DD") || "",
              });
              setToday(false);
            }}
          />

          <IconButton
            onClick={handleRefresh}
            type="primary"
            nextIcon={<RefreshIcon />}
          >
            Refresh
          </IconButton>
          {/* <DatePicker onChange={handleDateChange} /> */}
        </Space>
      }
    >
      {loading ? (
        <div className="h-28">
          <Loader />
        </div>
      ) : null}
      <section>
        {/*  className="grid grid-cols-2 lg:grid-cols-3 gap-5" */}
        <div>
          {/*  className="lg:col-span-2" */}
          {/* <div className="grid md:grid-cols-1 lg:grid-cols-3 gap-5">
            <div className="lg:col-span-2">
              <TopBar />
            </div>
          </div> */}
          <TopBarLog today={today} date={date} />
          <Map />
          <SiteLocator refresh={refresh} removeRefresh={removeRefresh} />
        </div>

        {/* <SARequestList /> */}
      </section>
    </AdminLayout>
  );
}
