import {
  Badge,
  Button,
  Drawer,
  Dropdown,
  Empty,
  Layout,
  Menu,
  MenuProps,
  theme,
} from "antd";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import withSidebar from "../HOC/withSidebar";
import NotificationHeaderIcon from "../assets/icons/NotificationHeaderIcon";
import AlarmIcon from "../assets/icons/sidebar/AlarmIcon";
import DashboardIcon from "../assets/icons/sidebar/DashboardIcon";
import GeoIcon from "../assets/icons/sidebar/GeoIcon";
import NetworkIcon from "../assets/icons/sidebar/NetworkIcon";
import PaymentIcon from "../assets/icons/sidebar/PaymentIcon";
import ResourceIcon from "../assets/icons/sidebar/ResourceIcon";
import SidebarBashIcon from "../assets/icons/sidebar/SidebarBashIcon";
import SubLocation from "../assets/icons/sidebar/SubLocation";
import SupportAgentsIcon from "../assets/icons/sidebar/SupportAgentsIcon";
import MainLogoSA from "../assets/images/main-logo-sa.svg";
import MainLogo from "../assets/images/main-logo.svg";
import Image from "../components/common/Image";
import ProfileMenu from "../components/common/ProfileMenu";
import { APP_NAME } from "../helpers/config";
import { useAppSelector } from "../store/store";
import { activePathString, pathString, stringToArray } from "../utils/array";

const { Header, Sider, Content } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

export type SidebarProps = {
  isMobile: boolean;
  collapsed: boolean;
  openSidebar: boolean;
  openMenuKeys: string[];
  handleOpenMenu: () => void;
  handleCollapsed: () => void;
  handleShowSidebar: () => void;
  handleCloseSidebar: () => void;
};

const SidebarLayout: React.FC<SidebarProps> = ({
  isMobile,
  collapsed,
  openSidebar,
  openMenuKeys,
  handleOpenMenu,
  handleCollapsed,
  handleShowSidebar,
  handleCloseSidebar,
}) => {
  let dynamicParams = useParams();
  const location = useLocation();
  const { siteRole } = useAppSelector((state) => state.login);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const currentPath = stringToArray(location.pathname, "/")?.[0];
  const isSA = stringToArray(location.pathname, "/")?.[0] === "sa";

  const dynamicPath = Object.keys(dynamicParams || {})?.length;

  // console.log(dynamicPath);

  function getItem(
    label: React.ReactNode,
    path: string,
    icon?: React.ReactNode,
    children?: MenuItem[],
    roles?: string[]
  ): MenuItem {
    return {
      key: pathString(path),
      icon,
      children,
      label: children?.length ? label : <Link to={path}>{label}</Link>,
      roles,
    } as MenuItem;
  }

  const NPMenuItems: MenuItem[] = [
    getItem("Dashboard", "/np/dashboard", <DashboardIcon />),
    getItem("Networks", "/np/networks", <NetworkIcon />),
    getItem("Alarm", "/np/alarm", <AlarmIcon />),
    // getItem("Support Agents", "/np/support-agents", <SupportAgentsIcon />),
    // getItem("Request", "/np/request", <RequestIcon />),
    getItem("Payment", "/np/payment", <PaymentIcon />),
    getItem("Geo Info", "/np/geo-info", <GeoIcon />, [
      getItem("Region", "/np/geo-info/region", <SubLocation />),
      getItem("Area", "/np/geo-info/area", <SubLocation />),
      getItem("Territory", "/np/geo-info/territory", <SubLocation />),
      // getItem("geo-details", "/np/geo-info/geo-details"),
    ]),
    getItem("Supplier", "/np/supplier", <SupportAgentsIcon />),
    getItem("Resource", "/np/resource", <ResourceIcon />),
    getItem("Regional Admin", "/np/regional-admin", <ResourceIcon />),
    getItem("Finance", "/np/finance", <ResourceIcon />),
    // getItem("Tasks", "/np/tasks", <TasksIcon />),
    // getItem("Settings", "/np/settings", <SettingsIcon />),
  ];

  const SAMenuItems: MenuItem[] = [
    getItem("Dashboard", "/sa/dashboard", <DashboardIcon />),
    getItem("Networks", "/sa/networks", <NetworkIcon />),
    getItem("Alarm", "/sa/alarm", <AlarmIcon />),
    // getItem(
    //   "Support Operators",
    //   "/sa/support-operators",
    //   <SupportAgentsIcon />
    // ),
    // getItem("Request", "/sa/request", <RequestIcon />),
    // getItem("Payment", "/sa/payment", <PaymentIcon />),
    getItem("Geo Info", "/sa/geo-info", <GeoIcon />, [
      getItem("Region", "/sa/geo-info/region", <SubLocation />),
      getItem("Area", "/sa/geo-info/area", <SubLocation />),
      getItem("Territory", "/sa/geo-info/territory", <SubLocation />),
      // getItem("geo-details", "/sa/geo-info/geo-details"),
    ]),
    getItem("Supplier", "/sa/supplier", <SupportAgentsIcon />),
    getItem("Resource", "/sa/resource", <ResourceIcon />),
    // getItem("Regional Admin", "/sa/regional-admin", <ResourceIcon />),
    // getItem("Tasks", "/sa/tasks", <TasksIcon />),
    // getItem("Settings", "/sa/settings", <SettingsIcon />),
  ];

  const VendorMenuItems: MenuItem[] = [
    // getItem("Geo Info", "/vendor/geo-info", <GeoIcon />, [
    //   getItem("Region", "/vendor/geo-info/region", <SubLocation />),
    //   getItem("Area", "/vendor/geo-info/area", <SubLocation />),
    //   getItem("Territory", "/vendor/geo-info/territory", <SubLocation />),
    // ]),
    getItem("Dashboard", "/vendor/dashboard", <DashboardIcon />),
    getItem("Network", "/vendor/networks", <NetworkIcon />),
    getItem("Profile", "/vendor/supplier", <SupportAgentsIcon />),
    getItem("Resource", "/vendor/resource", <ResourceIcon />),
  ];

  const RegionalMenuItems: MenuItem[] = [
    getItem("Dashboard", "/regional/dashboard", <DashboardIcon />),
    getItem("Networks", "/regional/networks", <NetworkIcon />),
    getItem("Payment", "/regional/payment", <PaymentIcon />),
    getItem("Supplier", "/regional/supplier", <SupportAgentsIcon />),
    getItem("Resource", "/regional/resource", <ResourceIcon />),
  ];

  const FinanceMenuItems: MenuItem[] = [
    getItem("Dashboard", "/finance/dashboard", <DashboardIcon />),
    getItem("Networks", "/finance/networks", <NetworkIcon />),
    getItem("Payment", "/finance/payment", <PaymentIcon />),
    getItem("Supplier", "/finance/supplier", <SupportAgentsIcon />),
    getItem("Resource", "/finance/resource", <ResourceIcon />),
  ];

  let menuList = [] as MenuItem[];

  if (siteRole === "NP") menuList = NPMenuItems;
  else if (siteRole === "SA") menuList = SAMenuItems;
  else if (siteRole === "VENDOR") menuList = VendorMenuItems;
  else if (siteRole === "REGIONAL") menuList = RegionalMenuItems;
  else if (siteRole === "FINANCE") menuList = FinanceMenuItems;

  function getActiveMenu(data: any[]) {
    const strArr = stringToArray(location.pathname, "/");
    return data?.find((el) => el?.key === strArr[1])?.children ? true : false;
  }

  return (
    <Layout className="min-h-screen">
      <div className="md:hidden">
        <Drawer
          placement="left"
          closable={false}
          onClose={handleCloseSidebar}
          open={openSidebar}
          key="left"
          contentWrapperStyle={{ width: "auto" }}
          bodyStyle={{ padding: 0 }}
        >
          <Sider
            trigger={null}
            theme="dark"
            collapsible
            collapsed={false}
            className="!overflow-auto !h-screen custom-sidebar"
            width={310}
          >
            <div className="h-20 flex justify-center items-center">
              <Link to={`/${currentPath}/dashboard`}>
                <Image
                  width={55}
                  src={isSA ? MainLogoSA : MainLogo}
                  alt={APP_NAME}
                />
              </Link>
            </div>

            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[
                activePathString(
                  location.pathname,
                  "/",
                  dynamicPath ? true : false,
                  getActiveMenu(menuList)
                ),
              ]}
              openKeys={openMenuKeys}
              onOpenChange={handleOpenMenu}
              items={menuList}
              className="sidebar-menu-item"
            />
          </Sider>
        </Drawer>
      </div>
      <div className="hidden md:block">
        <Sider
          trigger={null}
          theme="dark"
          collapsible
          collapsed={collapsed}
          className="!overflow-auto !h-screen !sticky top-0 custom-sidebar"
          width={310}
        >
          <div className={`h-20 flex justify-center items-center text-center`}>
            <Link to={`/${currentPath}/dashboard`}>
              <Image
                width={collapsed ? 40 : 50}
                src={isSA ? MainLogoSA : MainLogo}
                alt={APP_NAME}
              />
            </Link>
          </div>

          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[
              activePathString(
                location.pathname,
                "/",
                dynamicPath ? true : false,
                getActiveMenu(menuList)
              ),
            ]}
            openKeys={openMenuKeys}
            onOpenChange={handleOpenMenu}
            items={menuList}
            className={`sidebar-menu-item ${
              collapsed ? "sidebar_collapsed" : ""
            }`}
          />
        </Sider>
      </div>

      <Layout className="site-layout transition-all">
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <div className="flex items-center max-h-full justify-between pr-5">
            <div className="w-16 h-16 flex justify-center items-center">
              <Button
                type="text"
                icon={<SidebarBashIcon />}
                onClick={handleShowSidebar}
                className="md:hidden flex justify-center items-center"
              />
              <Button
                type="text"
                icon={collapsed ? <SidebarBashIcon /> : <SidebarBashIcon />}
                onClick={handleCollapsed}
                className="hidden md:flex justify-center items-center"
              />
            </div>

            <div className="flex items-center gap-5">
              <Dropdown
                trigger={["click"]}
                placement="bottomRight"
                arrow={{ pointAtCenter: true }}
                dropdownRender={() => (
                  <div className="w-64 bg-white shadow rounded-md">
                    <div className="flex items-center justify-between gap-2 py-1.5 px-3 shadow-sm shadow-gray-100 sticky top-0">
                      <span className="text-xs text-header font-medium">
                        Notifications
                      </span>
                      {/* <Link
                        className="text-xs text-body font-medium hover:text-header"
                        to="/agent/notifications"
                      >
                        View All
                      </Link> */}
                    </div>

                    <div className="pb-3">
                      <div className="h-48 overflow-y-auto px-3 pt-2 notification">
                        <Empty
                          imageStyle={{
                            height: 60,
                            marginTop: 10,
                          }}
                          description={
                            <span className="text-body font-medium text-sm">
                              No Data
                            </span>
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              >
                <Badge count={0}>
                  <Button
                    className="border-none shadow-sm"
                    icon={<NotificationHeaderIcon />}
                    size="middle"
                  />
                </Badge>
              </Dropdown>
              <ProfileMenu />
            </div>
          </div>
        </Header>

        <Content
          style={{
            margin: 20,
            padding: "16px 20px",
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default withSidebar(SidebarLayout);
