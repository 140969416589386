import { createSlice } from "@reduxjs/toolkit";
import {
  GetAllPaymentData,
  PaymentInitialStatus,
} from "../../../../types/redux";
import { getAllPaymentAsync, getCountPaymentAsync } from "./paymentAPI";

const initialState: PaymentInitialStatus<GetAllPaymentData> = {
  loading: false,
  postLoading: false,
  deleteLoading: false,
  updateLoading: false,
  allPayment: [],
  paymentCount: 0,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all Payment
    builder
      .addCase(getAllPaymentAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPaymentAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.allPayment = action.payload;
      })
      .addCase(getAllPaymentAsync.rejected, (state) => {
        state.loading = false;
        state.allPayment = [];
      });
    // get payment count
    builder
      .addCase(getCountPaymentAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCountPaymentAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentCount = action.payload;
      })
      .addCase(getCountPaymentAsync.rejected, (state) => {
        state.loading = false;
        state.paymentCount = 0;
      });
  },
});

export default paymentSlice;
