import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import appSlice from "./features/appSlice";
import loginSlice from "./features/auth/loginSlice";
import dashboardSlice from "./features/NP/dashboard/dashboardSlice";
import npGeoSlice from "./features/NP/geoInfo/geoSlice";
import networkSlice from "./features/NP/network/networkSlice";
import paymentSlice from "./features/NP/payment/paymentSlice";
import resourceSlice from "./features/NP/resource/resourceSlice";
import supplierSlice from "./features/NP/supplier/supplierSlice";

export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    login: loginSlice.reducer,
    npGeo: npGeoSlice.reducer,
    resource: resourceSlice.reducer,
    network: networkSlice.reducer,
    supplier: supplierSlice.reducer,
    dashboard: dashboardSlice.reducer,
    payment: paymentSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
