import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  FormProps,
  Input,
  InputNumber,
  Select,
} from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import CustomForm from "../../../components/common/CustomForm";
import Loader from "../../../components/common/Loader";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { ArrayOption, selectSearchOption } from "../../../helpers/siteConfig";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  getAllAreaAsync,
  getAllRegionAsync,
  getAllTerritoryAsync,
} from "../../../store/features/NP/geoInfo/geoAPI";
import {
  createTowerAsync,
  getSingleTicketAsync,
  updateTowerAsync,
} from "../../../store/features/NP/network/networkAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { TowerData } from "../../../types/redux";
import { stringToArray } from "../../../utils/array";
import { capitalize } from "../../../utils/string";
interface CheckListItem {
  name: string;
  numberOfPhoto: number;
}

export default function ManageNetwork() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const path = stringToArray(location.pathname, "/")?.[0];
  const [form] = Form.useForm();
  const [selectRegion, setSelectRegion] = useState("");
  const [selectArea, setSelectArea] = useState("");
  const [permissionError, setPermissionError] = useState("");
  const [checkListData, setCheckListData] = useState<CheckboxValueType[]>([""]);
  const { login } = useAppSelector((state) => state.login);
  const { regions, areas, territorys } = useAppSelector((state) => state.npGeo);
  const { postLoading, updateLoading, viewLoading, singleTower } =
    useAppSelector((state) => state.network);

  const navLink = `/${path}/networks`;

  const onFinish: FormProps<TowerData>["onFinish"] = (values) => {
    const { towerName, region, area, teritory, optionalContactNo, ...rest } =
      values;

    const data = {
      ...rest,
      towerName: towerName.toLowerCase(),
      region,
      area,
      teritory,
      regionName: regions?.find((el) => el?._id === region)?.regionName || "",
      areaName: areas?.find((el) => el?._id === area)?.areaName || "",
      teritoryName:
        territorys?.find((el) => el?._id === teritory)?.teritoryName || "",
      optionalContactNo: optionalContactNo?.filter((el: string) => el),
    };

    if (id) {
      dispatch(
        updateTowerAsync({
          data,
          others: {
            reRender,
            id,
          },
        })
      );
    } else {
      dispatch(
        createTowerAsync({
          data,
          others: {
            reRender,
          },
        })
      );
    }
  };

  // redirect and re-render function
  const reRender = (val: boolean) => {
    if (val) navigate(navLink);
  };

  const handleCheckboxChange = (checkedValues: CheckboxValueType[]) => {
    if (checkedValues?.length === 0) {
      setPermissionError("At least one permission must be selected");
    } else {
      setPermissionError("");
    }

    setCheckListData(checkedValues);

    const newValues: CheckListItem[] = checkedValues?.map((value) => ({
      name: value as string,
      numberOfPhoto: 1,
    }));

    form.setFieldsValue({ checkList: newValues });
  };

  const checkTowerNameExist = debounce(
    (towerName: string, callback: (result: boolean) => void) => {
      AXIOS.post(BASE_API.checkTowerName, { towerName, isPG: true })
        .then(() => callback(false))
        .catch(() => callback(true));
    },
    500
  );

  const checkSiteCodeExist = debounce(
    (siteCode: string, callback: (result: boolean) => void) => {
      AXIOS.post(BASE_API.checkSiteCode, { siteCode, isPG: true })
        .then(() => callback(false))
        .catch(() => callback(true));
    },
    500
  );

  useEffect(() => {
    dispatch(getAllRegionAsync({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getAllAreaAsync({
        params: {
          region: selectRegion,
        },
      })
    );
  }, [dispatch, selectRegion]);

  useEffect(() => {
    dispatch(
      getAllTerritoryAsync({
        params: {
          area: selectArea,
        },
      })
    );
  }, [dispatch, selectArea]);

  useEffect(() => {
    if (id) {
      dispatch(
        getSingleTicketAsync({
          params: { id },
        })
      );
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id && singleTower && updateLoading === false) {
      setSelectRegion(singleTower?.region?._id);
      setSelectArea(singleTower?.area?._id);

      setCheckListData(
        singleTower?.checkList?.map((el: any) => el?.name || "")
      );

      form.setFieldsValue({
        towerName: capitalize(singleTower?.towerName),
        contactNo: singleTower?.contactNo,
        optionalContactNo: singleTower?.optionalContactNo?.length
          ? singleTower?.optionalContactNo
          : [undefined],
        region: singleTower?.region?._id,
        area: singleTower?.area?._id,
        teritory: singleTower?.teritory?._id,
        latitude: singleTower?.latitude,
        longitude: singleTower?.longitude,
        radius: singleTower?.radius,
        siteCode: singleTower?.siteCode,
        kpi: singleTower?.kpi,
        checkList: singleTower?.checkList,
        address: singleTower?.address,
        contactPersonName: singleTower?.contactPersonName,
      });
    }
  }, [form, id, singleTower, updateLoading]);

  useEffect(() => {
    if (
      !id &&
      (login?.role === "region" || login?.role === "finance") &&
      updateLoading === false
    ) {
      form.setFieldsValue({
        region: login?.region,
      });

      setSelectRegion(login?.region);
    }
  }, [form, id, login?.region, login?.role, updateLoading]);

  // useEffect(() => {
  //   form
  //     .validateFields()
  //     .then((values) => {
  //       if (values.checkList.length === 0) {
  //         setPermissionError("At least one option must be selected.");
  //       } else {
  //         setPermissionError("");
  //       }
  //     })
  //     .catch(() => {
  //       setPermissionError("At least one option must be selected.");
  //     });
  // }, [form]);

  // console.log(form);

  return (
    <AdminLayout
      title={`${id ? "Update" : "Add New"} Tower`}
      headerTitle="Networks"
    >
      {viewLoading ? (
        <div className="pt-5">
          <Loader />
        </div>
      ) : (
        <CustomForm<TowerData>
          onFinish={onFinish}
          title={`${id ? "Update" : "Add New"} Tower`}
          formTitle={`${id ? "Update" : "Add new"} tower information`}
          backLink={navLink}
          disabled={postLoading || updateLoading}
          disabledSubmit={viewLoading}
          isUpdate={id ? true : false}
          formProps={{
            form,
          }}
          buttonProps={{
            onClick: () => {
              const checkListData = form.getFieldValue("checkList") || [];
              setPermissionError(
                checkListData?.length === 0
                  ? "At least one permission must be selected"
                  : ""
              );
            },
          }}
        >
          <Form.Item
            name="towerName"
            label="Tower Name"
            rules={[
              {
                required: true,
                message: "Please enter tower name",
              },
              {
                validator: async (_: any, value: string) => {
                  if (!value) {
                    return Promise.resolve();
                  }
                  if (id && capitalize(singleTower?.towerName) === value) {
                    return Promise.resolve();
                  } else {
                    return new Promise<void>((resolve, reject) => {
                      checkTowerNameExist(
                        value.toLowerCase(),
                        (result: boolean) => {
                          if (result) {
                            reject(new Error("Tower name already exists"));
                          } else {
                            resolve();
                          }
                        }
                      );
                    });
                  }
                },
              },
            ]}
          >
            <Input size="large" placeholder="Enter tower name" />
          </Form.Item>

          <Form.Item
            name="contactPersonName"
            label="Contact Person Name"
            rules={[
              {
                required: true,
                message: "Please enter contact person name",
              },
            ]}
          >
            <Input size="large" placeholder="Enter person name" />
          </Form.Item>

          <Form.Item
            name="contactNo"
            label="Land Proprietor Contact no"
            rules={[
              {
                required: true,
                message: "Please enter mobile no",
              },
              {
                pattern: /^(\+88)?(01[3-9])[0-9]{8}$/,
                message: "Please enter a valid Bangladeshi phone number",
              },
            ]}
          >
            <Input size="large" prefix={"BD "} placeholder="Mobile No:" />
          </Form.Item>

          <Form.List initialValue={[undefined]} name="optionalContactNo">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields?.map((field, index) => (
                    <div
                      className="grid grid-cols-7 items-center"
                      key={field.key}
                    >
                      <Form.Item
                        name={[index]}
                        label="Optional Contact no"
                        rules={[
                          // {
                          //   required: true,
                          //   message: "Please enter mobile no",
                          // },
                          {
                            pattern: /^(\+88)?(01[3-9])[0-9]{8}$/,
                            message:
                              "Please enter a valid Bangladeshi phone number",
                          },
                        ]}
                        className="col-span-6"
                      >
                        <Input
                          size="large"
                          prefix={"BD "}
                          placeholder="Mobile No:"
                        />
                      </Form.Item>

                      {fields.length > 1 ? (
                        <div className="flex justify-end mt-1">
                          <button
                            className="w-8 h-8 rounded-full bg-[#FFDCDA] border-none cursor-pointer"
                            onClick={() => remove(field.name)}
                          >
                            <DeleteIcon />
                          </button>
                        </div>
                      ) : null}
                    </div>
                  ))}
                  <div className="flex justify-end">
                    <Button type="primary" onClick={() => add()}>
                      <PlusOutlined /> Add Contact
                    </Button>
                  </div>
                </div>
              );
            }}
          </Form.List>

          <Form.Item
            name="region"
            label="Select Region"
            rules={[
              {
                required: true,
                message: "Please select region",
              },
            ]}
          >
            <Select
              placeholder="Select region"
              size="large"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={ArrayOption(regions, "regionName", "_id")}
              onChange={(val) => {
                setSelectRegion(val);
                setSelectArea("");
                form.setFieldValue("area", undefined);
                form.setFieldValue("teritory", undefined);
              }}
              disabled={login?.role === "region" || login?.role === "finance"}
            />
          </Form.Item>
          <Form.Item
            name="area"
            label="Select Area"
            rules={[
              {
                required: true,
                message: "Please select area",
              },
            ]}
          >
            <Select
              placeholder="Select area"
              size="large"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={ArrayOption(areas, "areaName", "_id")}
              onChange={(val) => {
                setSelectArea(val);
                form.setFieldValue("teritory", undefined);
              }}
              disabled={!selectRegion}
            />
          </Form.Item>
          <Form.Item
            name="teritory"
            label="Select Territory"
            rules={[
              {
                required: true,
                message: "Please select territory",
              },
            ]}
          >
            <Select
              placeholder="Select territory"
              size="large"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={ArrayOption(territorys, "teritoryName", "_id")}
              disabled={!selectArea}
            />
          </Form.Item>

          <Form.Item
            name="address"
            label="Address"
            rules={[
              {
                required: true,
                message: "Please enter address",
              },
            ]}
          >
            <Input size="large" placeholder="Enter address" />
          </Form.Item>

          <Form.Item
            name="latitude"
            label="Latitude"
            rules={[
              {
                required: true,
                message: "Please enter latitude",
              },
              {
                type: "number",
                min: 0,
                message: "Latitude can't be negative",
              },
            ]}
          >
            <InputNumber
              className="w-full"
              size="large"
              placeholder="Enter latitude"
            />
          </Form.Item>
          <Form.Item
            name="longitude"
            label="Longitude"
            rules={[
              {
                required: true,
                message: "Please enter longitude",
              },
              {
                type: "number",
                min: 0,
                message: "Longitude can't be negative",
              },
            ]}
          >
            <InputNumber
              className="w-full"
              size="large"
              placeholder="Enter longitude"
            />
          </Form.Item>

          <Form.Item
            name="radius"
            label="Radius (m)"
            rules={[
              {
                required: true,
                message: "Please enter radius no",
              },
              {
                type: "number",
                min: 0,
                message: "Radius can't be negative",
              },
            ]}
          >
            <InputNumber
              className="w-full"
              size="large"
              placeholder="Enter radius no"
            />
          </Form.Item>

          <Form.Item
            name="siteCode"
            label="Site Code"
            rules={[
              {
                required: true,
                message: "Please enter site code",
              },
              {
                validator: async (_: any, value: string) => {
                  if (!value) {
                    return Promise.resolve();
                  }

                  const spaceRegex = /^\S+$/;
                  if (!spaceRegex.test(value)) {
                    return Promise.reject(
                      new Error("Please enter valid site code")
                    );
                  }

                  if (id && singleTower?.siteCode === value) {
                    return Promise.resolve();
                  } else {
                    return new Promise<void>((resolve, reject) => {
                      checkSiteCodeExist(value, (result: boolean) => {
                        if (result) {
                          reject(new Error("Site code already exists"));
                        } else {
                          resolve();
                        }
                      });
                    });
                  }
                },
              },
            ]}
          >
            <Input size="large" placeholder="Enter site code" />
          </Form.Item>

          <Form.List initialValue={[phaseList[0].value]} name="kpi">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields?.map((field, index) => (
                    <div
                      className="grid grid-cols-7 items-center"
                      key={field.key}
                    >
                      <Form.Item
                        label="Select Phase"
                        name={[index]}
                        rules={[
                          {
                            required: true,
                            message: "Please select phase",
                          },
                        ]}
                        className="col-span-6"
                      >
                        <Select
                          placeholder="Select phase"
                          size="large"
                          allowClear
                          showSearch
                          filterOption={selectSearchOption}
                          options={ArrayOption(phaseList, "name", "value")}
                        />
                      </Form.Item>

                      {fields.length > 1 ? (
                        <div className="flex justify-end mt-1">
                          <button
                            className="w-8 h-8 rounded-full bg-[#FFDCDA] border-none cursor-pointer"
                            onClick={() => remove(field.name)}
                          >
                            <DeleteIcon />
                          </button>
                        </div>
                      ) : null}
                    </div>
                  ))}
                  <div className="flex justify-end">
                    <Button type="primary" onClick={() => add()}>
                      <PlusOutlined /> Add Phases
                    </Button>
                  </div>
                </div>
              );
            }}
          </Form.List>

          <p className="text-primary-np !text-lg font-medium border-t border-x-0 border-b-0 border-solid border-zinc-200 mt-4 pt-4 mb-2">
            PG check Permissions:
          </p>
          <Form.List
            name="checkList"
            rules={[
              {
                validator: async (_, checkList) => {
                  if (!checkList || checkList?.length === 0) {
                    return Promise.reject(
                      new Error("At least one permission must be selected")
                    );
                  }
                },
              },
            ]}
          >
            {() => (
              <div>
                <Checkbox.Group
                  options={checkListOptions}
                  onChange={handleCheckboxChange}
                  className="grid grid-cols-2 gap-y-1 gap-x-2"
                  value={checkListData}
                />
              </div>
            )}
          </Form.List>
          {permissionError ? (
            <div className="mt-1.5">
              <span className="ant-form-item-explain-error text-[#ff4d4f]">
                {permissionError}
              </span>
            </div>
          ) : null}
          <br />
        </CustomForm>
      )}
    </AdminLayout>
  );
}

const phaseList = [
  {
    name: "Robi",
    value: "Robi",
  },
  {
    name: "Airtel",
    value: "Airtel",
  },
  {
    name: "Grameenphone",
    value: "Grameenphone",
  },

  {
    name: "Banglalink",
    value: "Banglalink",
  },
  {
    name: "Teletalk",
    value: "Teletalk",
  },
];

const checkListOptions = [
  "Commercial Power",
  "Rectifier Controller",
  "Battery",
];
// const checkListOptions = [
//   "Energy Meter",
//   "Voltage",
//   "Current",
//   "Module",
//   "Circuit Breaker",
//   "Display",
// ];
// const checkListOptions = [
//   // "Left Right photos",
//   // "Front Back photos",
//   "Site photos",
//   "Circuit Breaker",
//   "Site Apparatus",
//   "IVS Check",
//   "Site Accessories",
//   "3 modules",
//   "Battery",
//   "IVS check",
//   "Fan check",
//   "Take circuit board photos",
//   "Others",
// ];
// const checkListOptions = [
//   "Left Right photos",
//   "Front Back photos",
//   "Circuit Breaker photos",
//   "3 modules",
//   "Site is up photos",
//   "Battery",
//   "IVS check",
//   "Fan check",
//   "Take circuit board photos",
// ];
