import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetAllUsersData,
  GetSingleDataParams,
  Params,
  PostRequest,
  RegionalParams,
} from "../../../../types/redux";
import {
  GetAllSupplierData,
  SupplierData,
  SupplierGetAllParams,
  SupplierUserCountParams,
} from "../../../../types/redux/NP/supplier";

// create a supplier
export const createSupplierAsync = createAsyncThunk<
  null,
  PostRequest<SupplierData, CommonOthers>
>(
  "np-supplier/create-supplier",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createSupplier, data);
      reRender(true);
      toast("success", "Supplier create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Supplier create fail");
      return rejectWithValue(error);
    }
  }
);

// get all supplier
export const getAllSupplierAsync = createAsyncThunk<
  GetAllSupplierData[],
  Params<SupplierGetAllParams>,
  {
    rejectValue: ErrorMessage;
  }
>("np-supplier/getAllSupplier", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getAllSupplier, {
      params,
    });

    return data.data?.getData as GetAllSupplierData[];
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get all supplier data fail");
    return rejectWithValue(error);
  }
});

// get count all supplier
export const getCountSupplierAsync = createAsyncThunk<
  number,
  Params<RegionalParams>,
  {
    rejectValue: ErrorMessage;
  }
>("np-supplier/getCountSupplier", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getCountSupplier, { params });
    return data.data?.getData || 0;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get supplier count data fail");
    return rejectWithValue(error);
  }
});

// get count all supplier
export const getSupplierProfileAsync = createAsyncThunk<
  any,
  undefined,
  {
    rejectValue: ErrorMessage;
  }
>("np-supplier/getSupplierProfile", async (_, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getSupplierProfile);
    return data.data?.getData || 0;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get supplier profile data fail");
    return rejectWithValue(error);
  }
});

// get supplier user
export const getSupplierUserAsync = createAsyncThunk<
  GetAllUsersData[],
  Params<SupplierUserCountParams>,
  {
    rejectValue: ErrorMessage;
  }
>("np-supplier/getSingleRegion", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.getSupplierUser}/${params?.id}`, {
      params,
    });

    return data.data?.getData as GetAllUsersData[];
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get user by supplier fail");
    return rejectWithValue(error);
  }
});

// get count all user supplier
export const getCountUserSupplierAsync = createAsyncThunk<
  number,
  Params<GetSingleDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "np-supplier/getCountUserSupplier",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(
        `${BASE_API.getCountUserSupplier}/${params?.id}`
      );
      return data.data?.getData || 0;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get supplier user count data fail");
      return rejectWithValue(error);
    }
  }
);
