import { Space } from "antd";
import Search from "antd/es/input/Search";
import { Link, useLocation } from "react-router-dom";
import AddIcon from "../../../assets/icons/AddIcon";
import IconButton from "../../../components/common/IconButton";
import RegionalAdminTable from "../../../components/NP/RegionalAdmin/RegionalAdminTable";
import Subheader from "../../../components/ui/Subheader";
import { pathLink } from "../../../helpers/siteConfig";
import useDebounce from "../../../hooks/useDebounce";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import { useAppSelector } from "../../../store/store";
import { AddZero } from "../../../utils/number";

export default function RegionalAdmin() {
  const { usersCount, loading, allUsers } = useAppSelector(
    (state) => state.resource
  );
  const { keyword, handleInputChange, handleEnterValue } = useDebounce();
  const { handleSetFirstPage, ...rest } = useTable();

  const location = useLocation();
  const path = pathLink(location.pathname, 1);

  // const handleSiteLocator = (value: string) => {
  //   console.log(`selected ${value}`);
  // };
  // const handleDateChange: DatePickerProps["onChange"] = (date, dateString) => {
  //   console.log(date, dateString);
  // };

  return (
    <AdminLayout title="Regional Admin List" headerTitle="Regional Admin">
      <section className="shadow-sm mt-7">
        <Subheader
          title="Regional Admin List"
          subTitle={`Total Regional Admin: ${AddZero(
            keyword && !loading ? allUsers?.length : usersCount
          )}`}
        >
          <Space size="middle">
            {/* <Select
              showSearch
              placeholder="Select a person"
              optionFilterProp="children"
              onChange={handleSiteLocator}
              filterOption={selectSearchOption}
              defaultValue="Inactive Networks"
              className="min-w-[8rem]"
              options={[
                {
                  value: "Inactive Networks",
                  label: "Inactive Networks",
                },
                {
                  value: "Pending",
                  label: "Pending",
                },
              ]}
            />
            <DatePicker onChange={handleDateChange} /> */}
            <Search
              placeholder="Search by name"
              onSearch={(val) => {
                handleEnterValue(val);
                handleSetFirstPage(true);
              }}
              onChange={(val) => {
                handleInputChange(val);
                handleSetFirstPage(true);
              }}
              className="custom-search-input"
            />
            <Link to={`/${path}/regional-admin/create`}>
              <IconButton type="primary" nextIcon={<AddIcon />}>
                Add new Regional Admin
              </IconButton>
            </Link>
          </Space>
        </Subheader>

        <RegionalAdminTable
          keyword={keyword}
          handleSetFirstPage={handleSetFirstPage}
          {...rest}
        />
      </section>
    </AdminLayout>
  );
}
