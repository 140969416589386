import { Button, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect } from "react";
import { addKeyInArray } from "../../../helpers/siteConfig";
import useTable from "../../../hooks/useTable";
import {
  getAllPaymentAsync,
  getCountPaymentAsync,
} from "../../../store/features/NP/payment/paymentAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { NetworkTableColumn } from "../../../types";
import { GetAllPaymentParams } from "../../../types/redux";
import { capitalize } from "../../../utils/string";
import CustomTable from "../../common/CustomTable";

interface PaymentTableProps {
  date: { from: string; to: string };
  status?: string;
  checked: "all" | "no";
}

export default function PaymentTable({
  date,
  checked,
  status,
}: PaymentTableProps) {
  const dispatch = useAppDispatch();
  const { limit, page, handlePagination } = useTable();
  const { login } = useAppSelector((state) => state.login);
  const { allPayment, paymentCount, loading } = useAppSelector(
    (state) => state.payment
  );

  const columns: ColumnsType<NetworkTableColumn> = [
    // {
    //   title: "Ticket",
    //   dataIndex: "towerTicketId",
    //   align: "center",
    // },

    {
      title: "Site Locator Name",
      render: (val) => (
        <Space className="table_column_title" size={0} direction="vertical">
          <h5>{val?.siteCode}</h5>
          <p>Tower name: {capitalize(val?.towerName)}</p>
        </Space>
      ),
    },

    {
      title: "PG Name",
      dataIndex: "pg",
      align: "center",
      render: (val) => `${val?.firstName} ${val?.lastName}`,
    },
    {
      title: "Region",
      dataIndex: "regionName",
      align: "center",
    },
    {
      title: "Area",
      dataIndex: "areaName",
      align: "center",
    },
    {
      title: "Territory",
      dataIndex: "teritoryName",
      align: "center",
    },
    {
      title: "Working Hours (minutes)",
      dataIndex: "minutesOfWork",
      align: "center",
      render: (val) => (val || 0) + " minutes",
    },

    {
      title: "Action",
      align: "center",
      dataIndex: "paid",
      render: (val) =>
        val ? (
          <Button
            size="small"
            type="primary"
            className="!cursor-default"
            disabled
          >
            Paid
          </Button>
        ) : (
          <Button
            size="small"
            type="default"
            className="!bg-gray-400 !text-white !cursor-default"
            disabled
          >
            Unpaid
          </Button>
        ),
    },
  ];

  const paymentData = useCallback(() => {
    const roles = {
      edatco: "admin",
      region: "region",
      finance: "finance",
    } as const;

    const params: GetAllPaymentParams = {
      limit: limit,
      skip: limit * (page - 1),
      role:
        checked === "no" && login?.role
          ? roles[login?.role as keyof typeof roles]
          : "all",
    };

    if (status) {
      params.paid = status;
    }

    if (date?.to && date?.from) {
      params.startDate = date?.to;
      params.endDate = date?.from;
    }

    if (login?.role === "region" || login?.role === "finance") {
      params.region = login?.region;
    }

    dispatch(
      getAllPaymentAsync({
        params,
      })
    );

    dispatch(
      getCountPaymentAsync({
        params,
      })
    );
  }, [
    checked,
    date?.from,
    date?.to,
    dispatch,
    limit,
    login?.region,
    login?.role,
    page,
    status,
  ]);

  useEffect(() => {
    paymentData();
  }, [paymentData]);

  return (
    <CustomTable<NetworkTableColumn>
      loading={loading}
      total={paymentCount}
      page={page}
      handlePagination={handlePagination}
      columns={columns}
      dataList={addKeyInArray(allPayment)}
    />
  );
}
