import { Avatar, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { userLogout } from "../../store/features/auth/loginSlice";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { firstChar } from "../../utils/string";

export default function ProfileMenu() {
  const dispatch = useAppDispatch();
  const { login, siteRole } = useAppSelector((state) => state.login);

  const profileData = {
    NP: "/np/dashboard",
    SA: "/sa/dashboard",
    VENDOR: "/vendor/supplier",
    REGIONAL: "/regional/dashboard",
    FINANCE: "/finance/dashboard",
  };

  return (
    <Dropdown
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
      className="cursor-pointer"
      rootClassName="profile-menu !left-auto !right-5"
      dropdownRender={() => (
        <ul className="list-none bg-white shadow p-1 rounded">
          <li>
            <Link
              to={siteRole ? profileData[siteRole] : ""}
              className="py-1.5 px-8 cursor-pointer select-none text-primary hover:text-primary hover:bg-slate-100 text-sm rounded block"
            >
              Profile
            </Link>
          </li>
          <li
            onClick={() => dispatch(userLogout())}
            className="py-1.5 px-8 cursor-pointer select-none text-primary hover:text-primary hover:bg-slate-100 text-sm rounded"
          >
            Logout
          </li>
        </ul>
      )}
    >
      <div className="flex items-center gap-1.5 cursor-pointer">
        <span className="font-semibold text-sm">{login?.firstName}</span>
        <Avatar size={40}>{firstChar(login?.firstName || "A")}</Avatar>
        {/* {login?.profilePicture ? (
          <img
            className="w-9 h-9 rounded-full"
            src={login?.profilePicture}
            alt={"User"}
          />
        ) : (
          <button className="w-9 h-9 rounded-full bg-accent-normal border-none text-white text-lg font-semibold cursor-pointer">
            {firstChar(login?.name)}
          </button>
        )} */}
      </div>
    </Dropdown>
  );
}
