import { Form, FormProps, Input, InputNumber, Select } from "antd";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomForm from "../../../components/common/CustomForm";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import {
  ArrayOption,
  pathLink,
  selectSearchOption,
} from "../../../helpers/siteConfig";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  getAllAreaAsync,
  getAllRegionAsync,
  getAllTerritoryAsync,
} from "../../../store/features/NP/geoInfo/geoAPI";
import { createUserAsync } from "../../../store/features/NP/resource/resourceAPI";
import { getAllSupplierAsync } from "../../../store/features/NP/supplier/supplierAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { UserData } from "../../../types/redux";
import { removeQuotes } from "../../../utils/string";

export default function ManageFinance() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [selectRegion, setSelectRegion] = useState("");
  const [selectArea, setSelectArea] = useState("");
  const { regions, areas, territorys } = useAppSelector((state) => state.npGeo);
  const { postLoading } = useAppSelector((state) => state.resource);
  const { allSupplier } = useAppSelector((state) => state.supplier);
  const { login } = useAppSelector((state) => state.login);
  const location = useLocation();
  const path = pathLink(location.pathname, 1);
  const navLink = `/${path}/finance`;

  const onFinish: FormProps<UserData>["onFinish"] = (values) => {
    // lattitude, longitude,
    const { region, area, teritory, nid, ...rest } = values;

    const data = {
      ...rest,
      nid: String(nid),
      role: "finance",
      region,
      area,
      teritory,
      regionName: regions?.find((el) => el?._id === region)?.regionName || "",
      areaName: areas?.find((el) => el?._id === area)?.areaName || "",
      teritoryName:
        territorys?.find((el) => el?._id === teritory)?.teritoryName || "",
      isPG: false,
      lattitude: 23.7055748,
      longitude: 90.3621026,
      location: {
        type: "Point",
        coordinates: [90.3621026, 23.7055748],
      },
      // lattitude,
      // longitude,
      // location: {
      //   type: "Point",
      //   coordinates: [longitude, lattitude],
      // },
    };

    // return console.log(data);

    dispatch(
      createUserAsync({
        data,
        others: {
          reRender,
        },
      })
    );
  };

  // redirect and re-render function
  const reRender = (val: boolean) => {
    if (val) navigate(navLink);
  };

  const checkEmployeeExist = debounce(
    (employeeId: string, callback: (result: string) => void) => {
      AXIOS.post(BASE_API.checkEmployeeID, { employeeId, isPG: true })
        .then(() => callback(""))
        .catch((err) =>
          callback(
            removeQuotes(
              err?.response?.data?.message || "Employee ID already exists"
            )
          )
        );
    },
    500
  );

  const checkEmailExist = debounce(
    (email: string, callback: (result: string) => void) => {
      AXIOS.post(BASE_API.checkEmail, { email, isPG: true })
        .then(() => callback(""))
        .catch((err) =>
          callback(
            removeQuotes(err?.response?.data?.message || "Email already exists")
          )
        );
    },
    500
  );

  const checkPhoneExist = debounce(
    (phoneNumber: string, callback: (result: string) => void) => {
      AXIOS.post(BASE_API.checkPhone, { phoneNumber, isPG: true })
        .then(() => callback(""))
        .catch((err) =>
          callback(
            removeQuotes(
              err?.response?.data?.message || "Phone number already exist"
            )
          )
        );
    },
    500
  );

  const checkNIDExist = debounce(
    (nid: number, callback: (result: string) => void) => {
      AXIOS.post(BASE_API.checkNID, { nid: String(nid), isPG: true })
        .then(() => callback(""))
        .catch((err) =>
          callback(
            removeQuotes(err?.response?.data?.message || "NID already exists")
          )
        );
    },
    500
  );

  useEffect(() => {
    dispatch(getAllRegionAsync({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getAllAreaAsync({
        params: {
          region: selectRegion,
        },
      })
    );
  }, [dispatch, selectRegion]);

  useEffect(() => {
    dispatch(
      getAllTerritoryAsync({
        params: {
          area: selectArea,
        },
      })
    );
  }, [dispatch, selectArea]);

  useEffect(() => {
    dispatch(getAllSupplierAsync({}));
  }, [dispatch]);

  // console.log(
  //   allSupplier?.find((el) => el?._id === login?.suppler),
  //   login?.suppler
  // );

  useEffect(() => {
    if (
      path === "vendor" &&
      allSupplier?.find((el) => el?._id === login?.suppler)
    ) {
      form.setFieldsValue({
        suppler: login?.suppler || undefined,
      });
    }
  }, [allSupplier, form, login?.suppler, path]);

  return (
    <AdminLayout title="Add New Finance" headerTitle="Finance">
      <CustomForm<UserData>
        onFinish={onFinish}
        title="Add New Finance"
        formTitle="Add Finance"
        backLink={navLink}
        disabled={postLoading}
        formProps={{
          form,
        }}
      >
        <Form.Item
          name="firstName"
          label="Operator First Name"
          rules={[
            {
              required: true,
              message: "Please enter operator first name",
            },
          ]}
        >
          <Input size="large" placeholder="Enter first name" />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Operator Last Name"
          rules={[
            {
              required: true,
              message: "Please enter operator last name",
            },
          ]}
        >
          <Input size="large" placeholder="Enter last name" />
        </Form.Item>

        <Form.Item
          name="phoneNumber"
          label="Contact Number"
          rules={[
            {
              required: true,
              message: "Please enter phone number",
            },
            {
              validator: async (_: any, value: string) => {
                if (!value) {
                  return Promise.resolve();
                }

                const phoneRegex = /^(\+88)?(01[3-9])[0-9]{8}$/;
                if (!phoneRegex.test(value)) {
                  return Promise.reject(
                    new Error("Please enter a valid Bangladeshi phone number")
                  );
                }

                return new Promise<void>((resolve, reject) => {
                  checkPhoneExist(value, (result: string) => {
                    if (result) {
                      reject(new Error(result));
                    } else {
                      resolve();
                    }
                  });
                });
              },
            },
          ]}
        >
          <Input prefix={"BD "} size="large" placeholder="Mobile No:" />
        </Form.Item>

        <Form.Item
          name="employeeId"
          label="Employee ID"
          rules={[
            {
              required: true,
              message: "Please enter employee ID",
            },
            {
              validator: async (_: any, value: string) => {
                if (!value) {
                  return Promise.resolve();
                }

                return new Promise<void>((resolve, reject) => {
                  checkEmployeeExist(value, (result: string) => {
                    if (result) {
                      reject(new Error(result));
                    } else {
                      resolve();
                    }
                  });
                });
              },
            },
          ]}
        >
          <Input size="large" placeholder="Enter employee ID" />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Please enter email",
            },
            {
              validator: async (_: any, value: string) => {
                if (!value) {
                  return Promise.resolve();
                }

                const emailRegex =
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                if (!emailRegex.test(value)) {
                  return Promise.reject(
                    new Error("Please enter a valid email")
                  );
                }

                return new Promise<void>((resolve, reject) => {
                  checkEmailExist(value, (result: string) => {
                    if (result) {
                      reject(new Error(result));
                    } else {
                      resolve();
                    }
                  });
                });
              },
            },
          ]}
        >
          <Input size="large" placeholder="Enter email" />
        </Form.Item>

        <Form.Item
          name="nid"
          label="NID"
          rules={[
            {
              required: true,
              message: "Please enter NID",
            },
            {
              validator: async (_: any, value: number) => {
                if (!value) {
                  return Promise.resolve();
                }

                if (value < 0) {
                  return Promise.reject(new Error("NID can't be negative"));
                }

                if (!Number.isInteger(value)) {
                  return Promise.reject(new Error("NID must be an integer"));
                }

                if (value.toString().length < 10) {
                  return Promise.reject(new Error("NID should be minimum 10"));
                }

                return new Promise<void>((resolve, reject) => {
                  checkNIDExist(value, (result: string) => {
                    if (result) {
                      reject(new Error(result));
                    } else {
                      resolve();
                    }
                  });
                });
              },
            },
          ]}
        >
          <InputNumber
            className="w-full"
            size="large"
            placeholder="Enter NID"
          />
        </Form.Item>

        <Form.Item
          name="region"
          label="Select Region"
          rules={[
            {
              required: true,
              message: "Please select region",
            },
          ]}
        >
          <Select
            placeholder="Select region"
            size="large"
            allowClear
            showSearch
            filterOption={selectSearchOption}
            options={ArrayOption(regions, "regionName", "_id")}
            onChange={(val) => {
              setSelectRegion(val);
              setSelectArea("");
              form.setFieldValue("area", undefined);
              form.setFieldValue("teritory", undefined);
            }}
          />
        </Form.Item>
        <Form.Item
          name="area"
          label="Select Area"
          rules={[
            {
              required: true,
              message: "Please select area",
            },
          ]}
        >
          <Select
            placeholder="Select area"
            size="large"
            allowClear
            showSearch
            filterOption={selectSearchOption}
            options={ArrayOption(areas, "areaName", "_id")}
            onChange={(val) => {
              setSelectArea(val);
              form.setFieldValue("teritory", undefined);
            }}
            disabled={!selectRegion}
          />
        </Form.Item>
        <Form.Item
          name="teritory"
          label="Select Territory"
          rules={[
            {
              required: true,
              message: "Please select territory",
            },
          ]}
        >
          <Select
            placeholder="Select territory"
            size="large"
            allowClear
            showSearch
            filterOption={selectSearchOption}
            options={ArrayOption(territorys, "teritoryName", "_id")}
            disabled={!selectArea}
          />
        </Form.Item>

        <Form.Item
          name="suppler"
          label="Select Supplier"
          rules={[
            {
              required: true,
              message: "Please select supplier",
            },
          ]}
        >
          <Select
            placeholder="Select supplier"
            size="large"
            allowClear
            showSearch
            filterOption={selectSearchOption}
            options={ArrayOption(allSupplier, "supplierName", "_id")}
            disabled={
              path === "vendor" &&
              allSupplier?.find((el) => el?._id === login?.suppler)
                ? true
                : false
            }
          />
        </Form.Item>

        {/* <Form.Item
          name="role"
          label="Select Role"
          rules={[
            {
              required: true,
              message: "Please select role",
            },
          ]}
          initialValue={"pg"}
        >
          <Select
            placeholder="Select role"
            size="large"
            allowClear
            showSearch
            filterOption={selectSearchOption}
            options={ArrayOption(roleData, "name", "value")}
          />
        </Form.Item> */}

        {/* <Form.Item
          name="lattitude"
          label="Latitude"
          rules={[
            {
              required: true,
              message: "Please enter latitude",
            },
            {
              type: "number",
              min: 0,
              message: "Latitude can't be negative",
            },
          ]}
        >
          <InputNumber
            className="w-full"
            size="large"
            placeholder="Enter latitude"
          />
        </Form.Item>

        <Form.Item
          name="longitude"
          label="Longitude"
          rules={[
            {
              required: true,
              message: "Please enter longitude",
            },
            {
              type: "number",
              min: 0,
              message: "Longitude can't be negative",
            },
          ]}
        >
          <InputNumber
            className="w-full"
            size="large"
            placeholder="Enter longitude"
          />
        </Form.Item> */}

        <Form.Item
          name="pass"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please enter password",
            },
            {
              min: 8,
              message: "Password must be minimum 8 characters.",
            },
          ]}
        >
          <Input.Password
            // prefix={<PassKeyIcon />}
            size="large"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item
          name="confirmPass"
          label="Confirm Password"
          rules={[
            {
              required: true,
              message: "Please enter confirm password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("pass") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Password and Confirm password do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password size="large" placeholder="Confirm Password" />
        </Form.Item>
      </CustomForm>
    </AdminLayout>
  );
}

// const roleData = [{ name: "PG", value: "pg" }];
