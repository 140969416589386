import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import {
  ErrorMessage,
  GetAllPaymentData,
  GetAllPaymentParams,
  Params,
} from "../../../../types/redux";

// get all users
export const getAllPaymentAsync = createAsyncThunk<
  GetAllPaymentData[],
  Params<GetAllPaymentParams>,
  {
    rejectValue: ErrorMessage;
  }
>("payment/getAllPayment", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getAllPayment, {
      params,
    });

    return data.data?.data as GetAllPaymentData[];
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get all payment fail");
    return rejectWithValue(error);
  }
});

// get count payment
export const getCountPaymentAsync = createAsyncThunk<
  number,
  Params<GetAllPaymentParams>,
  {
    rejectValue: ErrorMessage;
  }
>("payment/getCountPayment", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getCountPayment, {
      params,
    });
    return data.data?.count || 0;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get user count payment fail");
    return rejectWithValue(error);
  }
});
