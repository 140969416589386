import { Button, Progress, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import EditIcon from "../../../assets/icons/EditIcon";
import { addKeyInArray } from "../../../helpers/siteConfig";
import useModal from "../../../hooks/useModal";
import {
  cancelTowerTicketAsync,
  getAllCountTowerAsync2,
  getAllTowerAsync,
  getAllTowerAsync2,
  getCountTowerAsync,
  removePGFromTowerTicketAsync,
  towerTicketStartAsync,
  towerTicketStopAsync,
} from "../../../store/features/NP/network/networkAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { CustomTableProps, ModalType } from "../../../types";
import { GetAllTowerData, TableData } from "../../../types/redux";
import { stringToArray } from "../../../utils/array";
import { percentage } from "../../../utils/number";
import { capitalize } from "../../../utils/string";
import { formatDate, previousDate } from "../../../utils/time";
import ConfirmModal from "../../common/ConfirmModal";
import CustomTable from "../../common/CustomTable";
import Feedback from "./Feedback";
import ImportExcel from "./ImportExcel";
import TicketGenerate from "./TicketGenerate";
import View from "./View";
import ViewLog from "./ViewLog";

type Props = {
  keyword?: string;
  siteCode?: string;
  status: string;
  isVendor?: boolean;
  openImport: ModalType;
  toggleImport: () => void;
} & CustomTableProps;

export default function NetworkTable({
  status,
  keyword = "",
  siteCode = "",
  page,
  limit,
  isFirstPage,
  handlePagination,
  handleSetFirstPage,
  openImport,
  toggleImport,
  isVendor,
}: Props) {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { open, toggle, handleOpen } = useModal();
  const {
    open: confirmOpen,
    toggle: confirmToggle,
    handleOpen: handleConfirmOpen,
  } = useModal();
  const [uploadCount, setUploadCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const path = stringToArray(location.pathname, "/")?.[0];
  const { login } = useAppSelector((state) => state.login);
  const { loading, allTowers, towersCount, postLoading, updateLoading } =
    useAppSelector((state) => state.network);

  const pgStatus = status ? stringToArray(status, "-") : "";

  const uploadedCount = (value: number, total: number) => {
    setUploadCount(value);
    setTotalCount(total);
  };

  const columns: ColumnsType<TableData<GetAllTowerData>> = [
    {
      title: "Site Locator Name",
      render: (val: GetAllTowerData) => (
        <Space className="table_column_title" size={0} direction="vertical">
          <h5>{val?.siteCode}</h5>
          <p>Tower name: {capitalize(val?.towerName)}</p>
        </Space>
      ),
    },
    // {
    //   title: "Structure Type",
    //   dataIndex: "structureType",
    //   align: "center",
    // },
    // {
    //   title: "Location",
    //   align: "center",
    //   render: (val: NetworkTableColumn) => (
    //     <span
    //       className={`${
    //         val?.location === "Indoor"
    //           ? "text-status-indoor"
    //           : "text-status-outdoor"
    //       }`}
    //     >
    //       {val?.location || "-"}
    //     </span>
    //   ),
    // },
    {
      title: "Region",
      dataIndex: "regionName",
      align: "center",
    },
    {
      title: "Area",
      dataIndex: "areaName",
      align: "center",
    },
    {
      title: "Territory",
      dataIndex: "teritoryName",
      align: "center",
    },
    {
      title: "Agent Operator",
      dataIndex: "numberOfOperation",
      align: "center",
      render: (val) => val || 0,
    },

    {
      title: "Status",
      align: "center",
      render: (val: GetAllTowerData) => (
        <span
          className={`status ${
            val?.towerTicketLastData?.status === "pending"
              ? "status-pending"
              : val?.towerTicketLastData?.status === "accepted"
              ? "status-done"
              : val?.towerTicketLastData?.status === "notaccepted"
              ? "status-waiting"
              : val?.towerTicketLastData?.status === "cancel"
              ? "status-danger"
              : null
          }`}
        >
          {val?.towerTicketLastData?.status
            ? capitalize(val?.towerTicketLastData?.status)
            : "N/A"}
        </span>
      ),
    },
    {
      title: "PG Status",
      align: "center",
      render: (val: GetAllTowerData) => (
        <span
          className={`status ${
            val?.towerTicketLogData?.status === "pending"
              ? "status-pending"
              : val?.towerTicketLogData?.missCall
              ? "status-danger"
              : val?.towerTicketLogData?.status === "reached"
              ? "status-waiting"
              : val?.towerTicketLogData?.status === "finished"
              ? "status-done"
              : val?.towerTicketLogData?.status === "start"
              ? "status-info"
              : null
          }`}
        >
          {val?.towerTicketLogData?.missCall
            ? "Missed Call"
            : val?.towerTicketLogData?.status
            ? capitalize(val?.towerTicketLogData?.status)
            : "N/A"}
        </span>
      ),
    },
    {
      title: "Log View",
      align: "center",
      render: (val: GetAllTowerData) => (
        <button
          className={`status border-none ${
            val?.towerTicketLogData?._id
              ? "status-info cursor-pointer"
              : "status-info !text-gray-400"
          }`}
          onClick={() =>
            handleOpen({
              type: "LOG",
              data: val,
            })
          }
          disabled={!val?.towerTicketLogData?._id ? true : false}
        >
          View
        </button>
      ),
    },

    {
      title: "Action",
      align: "center",
      render: (val: GetAllTowerData) => (
        <div className="flex items-center gap-1 justify-center">
          {val?.towerTicketLogData?.status === "finished" &&
          !val?.towerTicketLogData?.rating ? (
            <Button
              className="btn-status btn-status-done"
              onClick={() =>
                handleOpen({
                  type: val?.towerTicketLogData?.status,
                  data: val,
                })
              }
              disabled={val?.towerTicketLogData?.rating ? true : false}
            >
              Feedback
            </Button>
          ) : val?.towerTicketLastData?.status === "accepted" &&
            val?.towerTicketLogData?.status !== "finished" &&
            val?.towerTicketLastData?._id &&
            !isVendor ? (
            <Button
              className="btn-status !bg-status-info-btn"
              onClick={() =>
                handleConfirmOpen({
                  type: "REMOVE_PG",
                  data: val?.towerTicketLastData?._id,
                })
              }
            >
              Remove PG
            </Button>
          ) : val?.towerTicketLastData?.status === "pending" &&
            !val?.towerTicketLogData?.status ? (
            <Button
              className="btn-status !bg-status-waiting-btn"
              onClick={() =>
                handleConfirmOpen({
                  type: "CANCEL_TICKET",
                  data: val?.towerTicketLastData?._id,
                })
              }
            >
              Cancel Ticket
            </Button>
          ) : (
            <Button
              className="btn-status btn-status-danger"
              onClick={() =>
                handleOpen({
                  type: "Inactive",
                  data: val,
                })
              }
              disabled={
                val?.towerTicketLogData?.status === "pending" ||
                val?.towerTicketLogData?.status === "reached" ||
                val?.towerTicketLogData?.status === "start" ||
                val?.towerTicketLastData?.status === "pending"
              }
            >
              Ticket Generate
            </Button>
          )}
        </div>
      ),
      // render: (val: GetAllTowerData) =>
      //   false ? (
      //     <Button
      //       // TODO: val?.status === "Inactive"
      //       className="btn-status btn-status-danger"
      //       onClick={() =>
      //         handleOpen({
      //           // TODO: type: val?.status,
      //           type: "Inactive",
      //           data: val,
      //         })
      //       }
      //       disabled={val?.towerTicketLogData?.status === "pending"}
      //     >
      //       Ticket Generate
      //     </Button>
      //   ) : val?.status === "Completed" ? (
      //     <Button
      //       className="btn-status btn-status-done"
      //       onClick={() =>
      //         handleOpen({
      //           type: val?.status,
      //           data: val,
      //         })
      //       }
      //     >
      //       Feedback
      //     </Button>
      //   ) : val?.status === "Pause" ? (
      //     <Button className="btn-status btn-status-waiting">Fix Now</Button>
      //   ) : val?.status === "Ongoing" ? (
      //     <Button
      //       className="btn-status btn-status-info"
      //       onClick={() =>
      //         handleOpen({
      //           type: val?.status,
      //           data: val,
      //         })
      //       }
      //     >
      //       View
      //     </Button>
      //   ) : val?.status === "Waiting for payment" ? (
      //     <Button className="btn-status btn-status-pending">Pay Now</Button>
      //   ) : null,
    },
    {
      title: "PG Action",
      align: "center",
      render: (val: GetAllTowerData) => (
        <div className="flex items-center gap-2">
          <Button
            type="primary"
            onClick={() =>
              handleConfirmOpen({
                type: "START_TICKET",
                data: val?.towerTicketLogData?._id,
              })
            }
            disabled={
              val?.towerTicketLogData?.status !== "reached" ||
              val?.towerTicketLogData?.pgStart?.time
                ? true
                : false
            }
          >
            PG Enable
          </Button>
          <Button
            onClick={() =>
              handleConfirmOpen({
                type: "STOP_TICKET",
                data: val?.towerTicketLogData?._id,
              })
            }
            className="btn-status !bg-status-waiting-btn disabled:!bg-status-waiting-btn/60"
            disabled={
              val?.towerTicketLogData?.pgStart?.time &&
              !val?.towerTicketLogData?.pgOff?.time
                ? false
                : true
            }
          >
            PG Disable
          </Button>
        </div>
      ),
    },
    {
      title: "Edit",
      align: "center",
      // width: 300,
      render: (val) => (
        <Link to={`/${path}/networks/update-tower/${val?._id}`}>
          <Button icon={<EditIcon />} />
          {/* <Button type="primary" icon={<EditFilled />} /> */}
        </Link>
      ),
    },
  ];

  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");

  const networkData = useCallback(() => {
    const params: any =
      keyword || siteCode
        ? { limit: 1000, skip: 0 }
        : {
            limit: limit,
            skip: limit * (page - 1),
          };

    if (login?.role === "region" || login?.role === "finance") {
      params.region = login?.region;
    }

    if (searchParams.get("today") === "true") {
      params.today = previousDate(formatDate(new Date(), "YYYY-MM-DD"));
    } else {
      params.startDate = startDate ? previousDate(startDate) : "";
      params.endDate = endDate ? previousDate(endDate) : "";
      params.today = "";
    }

    const filterData =
      status === "unrespond"
        ? { unrespond: true }
        : status === "missCall"
        ? { filter: ["finished"], missCall: true }
        : { filter: status ? [status] : [] };

    dispatch(
      getAllTowerAsync({
        data: {
          ...filterData,
          towerName: keyword,
          siteCode,
        },
        others: params,
      })
    );
  }, [
    keyword,
    siteCode,
    limit,
    page,
    login?.role,
    login?.region,
    searchParams,
    status,
    dispatch,
    startDate,
    endDate,
  ]);

  const networkData2 = useCallback(() => {
    const params: any =
      keyword || siteCode
        ? { limit: 1000, skip: 0 }
        : {
            limit: limit,
            skip: limit * (page - 1),
          };

    params.pg = pgStatus?.[1] || "";

    if (login?.role === "region" || login?.role === "finance") {
      params.region = login?.region;
    }

    if (searchParams.get("today") === "true") {
      params.today = previousDate(formatDate(new Date(), "YYYY-MM-DD"));
    } else {
      params.startDate = startDate ? previousDate(startDate) : "";
      params.endDate = endDate ? previousDate(endDate) : "";
      params.today = "";
    }

    params.towerName = keyword;
    params.siteCode = siteCode;

    dispatch(
      getAllTowerAsync2({
        params,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, keyword, siteCode, limit, page, pgStatus?.[1], searchParams]);

  useEffect(() => {
    if (pgStatus?.[0] === "pg") {
      networkData2();
    } else {
      networkData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkData, networkData2, pgStatus?.[0], searchParams]);

  const getCountTower = useCallback(() => {
    const params: any =
      keyword || siteCode
        ? { limit: 1000, skip: 0 }
        : {
            limit: limit,
            skip: limit * (page - 1),
          };

    if (login?.role === "region" || login?.role === "finance") {
      params.region = login?.region;
    }

    if (searchParams.get("today") === "true") {
      params.today = previousDate(formatDate(new Date(), "YYYY-MM-DD"));
    } else {
      params.startDate = startDate ? previousDate(startDate) : "";
      params.endDate = endDate ? previousDate(endDate) : "";
      params.today = "";
    }

    const filterData =
      status === "unrespond"
        ? { unrespond: true }
        : status === "missCall"
        ? { filter: ["finished"], missCall: true }
        : { filter: status ? [status] : [] };

    dispatch(
      getCountTowerAsync({
        data: {
          ...filterData,
          towerName: keyword,
          siteCode,
        },
        others: params,
      })
    );
  }, [
    keyword,
    siteCode,
    limit,
    page,
    login?.role,
    login?.region,
    searchParams,
    status,
    dispatch,
    startDate,
    endDate,
  ]);

  const getCountTower2 = useCallback(() => {
    const params: any =
      keyword || siteCode
        ? { limit: 1000, skip: 0 }
        : {
            limit: limit,
            skip: limit * (page - 1),
          };

    params.pg = pgStatus?.[1] || "";

    if (login?.role === "region" || login?.role === "finance") {
      params.region = login?.region;
    }

    if (searchParams.get("today") === "true") {
      params.today = previousDate(formatDate(new Date(), "YYYY-MM-DD"));
    } else {
      params.startDate = startDate ? previousDate(startDate) : "";
      params.endDate = endDate ? previousDate(endDate) : "";
      params.today = "";
    }

    params.towerName = keyword;
    params.siteCode = siteCode;

    dispatch(
      getAllCountTowerAsync2({
        params,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    keyword,
    siteCode,
    limit,
    page,
    login?.role,
    login?.region,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    pgStatus?.[1],
    searchParams,
  ]);

  useEffect(() => {
    if (pgStatus?.[0] === "pg") {
      getCountTower2();
    } else {
      getCountTower();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCountTower, getCountTower2, pgStatus?.[0], searchParams]);

  useEffect(() => {
    if ((status || keyword || siteCode) && isFirstPage) {
      handlePagination(1, 10);
      handleSetFirstPage(false);
    }
  }, [
    handlePagination,
    handleSetFirstPage,
    isFirstPage,
    keyword,
    siteCode,
    status,
  ]);

  function reRender(val: boolean) {
    if (val) {
      toggle();
      networkData();
      toggleImport();
      confirmToggle();
    }
  }

  const handleRemovePG = (id: string) => {
    dispatch(
      removePGFromTowerTicketAsync({
        data: null,
        others: {
          id,
          reRender,
        },
      })
    );
  };

  const handleTicketCancel = (id: string) => {
    dispatch(
      cancelTowerTicketAsync({
        data: null,
        others: {
          id,
          reRender,
        },
      })
    );
  };

  // PG start manually
  const handlePGStart = (id: string) => {
    dispatch(
      towerTicketStartAsync({
        data: null,
        others: {
          id,
          reRender,
        },
      })
    );
  };

  // PG stop manually
  const handlePGStop = (id: string) => {
    dispatch(
      towerTicketStopAsync({
        data: null,
        others: {
          id,
          reRender,
        },
      })
    );
  };

  return (
    <>
      {postLoading && uploadCount && totalCount ? (
        <Progress
          className="fixed w-full left-0 right-0 -top-[7px] z-[99999999999999999999999999999999]"
          percent={percentage(uploadCount / 2, totalCount)}
          showInfo={false}
          success={{ strokeColor: "#384DDA" }}
        />
      ) : null}

      {open.type === "Inactive" && (
        <TicketGenerate
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          reRender={reRender}
        />
      )}
      {open.type === "finished" && (
        <Feedback
          open={open.open}
          data={open?.data}
          handleClose={toggle}
          reRender={reRender}
        />
      )}
      {open.type === "Ongoing" && (
        <View open={true} data={open?.data} handleClose={toggle} />
      )}
      {confirmOpen?.type === "REMOVE_PG" && (
        <ConfirmModal
          open={confirmOpen?.open}
          data={confirmOpen?.data}
          handleClose={confirmToggle}
          deleteLoading={updateLoading}
          handleYes={handleRemovePG}
          title={null}
          deleteTitle="Remove PG"
          questionText="Are you sure to remove PG from this ticket?"
        />
      )}
      {confirmOpen?.type === "CANCEL_TICKET" && (
        <ConfirmModal
          open={confirmOpen?.open}
          data={confirmOpen?.data}
          handleClose={confirmToggle}
          deleteLoading={updateLoading}
          handleYes={handleTicketCancel}
          title={null}
          deleteTitle="Cancel Ticket"
          questionText="Are you sure to cancel this ticket?"
        />
      )}
      {confirmOpen?.type === "START_TICKET" && (
        <ConfirmModal
          open={confirmOpen?.open}
          data={confirmOpen?.data}
          handleClose={confirmToggle}
          deleteLoading={updateLoading}
          handleYes={handlePGStart}
          title={null}
          deleteTitle="Enable PG"
          questionText="Are sure to enable PG for this ticket?"
        />
      )}
      {confirmOpen?.type === "STOP_TICKET" && (
        <ConfirmModal
          open={confirmOpen?.open}
          data={confirmOpen?.data}
          handleClose={confirmToggle}
          deleteLoading={updateLoading}
          handleYes={handlePGStop}
          title={null}
          deleteTitle="Disable PG"
          questionText="Are sure to disable PG for this ticket?"
        />
      )}
      {open.type === "LOG" && (
        <ViewLog
          title="Site Locator Log"
          locatorTitle="Shilmoon West Para - Mymensingh"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
        />
      )}

      {openImport?.type === "IMPORT" && (
        <ImportExcel
          open={openImport.open}
          // data={open?.data}
          handleClose={toggleImport}
          reRender={reRender}
          uploadedCount={uploadedCount}
        />
      )}

      <CustomTable<TableData<GetAllTowerData>>
        loading={loading}
        total={
          (keyword || siteCode) && !loading ? allTowers?.length : towersCount
        }
        page={page}
        handlePagination={handlePagination}
        columns={isVendor ? columns?.slice(0, columns?.length - 2) : columns}
        dataList={addKeyInArray(allTowers)}
        isPagination={keyword ? false : true}
      />
    </>
  );
}
