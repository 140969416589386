import { Avatar, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { addKeyInArray, pathLink } from "../../../helpers/siteConfig";
import {
  getAllUsersAsync,
  getCountUsersAsync,
} from "../../../store/features/NP/resource/resourceAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { CustomTableProps } from "../../../types";
import {
  GetAllUsersData,
  GetAllUsersParams,
  TableData,
} from "../../../types/redux";
import { fixedNumber } from "../../../utils/number";
import { firstChar } from "../../../utils/string";
import { formatDate, minutesToHours } from "../../../utils/time";
import CustomTable from "../../common/CustomTable";

type Props = {
  keyword?: string;
} & CustomTableProps;

export default function FinanceTable({
  keyword = "",
  page,
  limit,
  isFirstPage,
  handlePagination,
  handleSetFirstPage,
}: Props) {
  const { login } = useAppSelector((state) => state.login);
  const dispatch = useAppDispatch();
  const { loading, allUsers, usersCount } = useAppSelector(
    (state) => state.resource
  );
  const location = useLocation();
  const path = pathLink(location.pathname, 1);

  // console.log(login);

  const columns: ColumnsType<TableData<GetAllUsersData>> = [
    {
      title: "Operator Name",
      render: (val: GetAllUsersData) => (
        <Space className="table_column_title" size={0} direction="horizontal">
          <Avatar size={45}>{firstChar(val?.firstName)}</Avatar>
          {/* <Avatar src={IMG} size={44}></Avatar> */}
          <div className="ml-[8px]">
            <h5>{`${val?.firstName} ${val?.lastName}`}</h5>
            <p className="mb-0.5">Email: {val?.email}</p>
            <p>Mobile: {val?.phoneNumber}</p>
          </div>
        </Space>
      ),
    },
    // {
    //   title: "Status",
    //   align: "center",
    //   render: (val: GetAllUsersData) => <span>-</span>,
    // },

    {
      title: "Total Minutes of Work (HH:MM)",
      align: "center",
      render: (val: GetAllUsersData) => (
        <span>{minutesToHours(val.totalMinutesOfWork || 0)} min</span>
      ),
    },
    {
      title: "Total Amount",
      align: "center",
      render: (val: GetAllUsersData) => <span>{val.totalAmount || 0} BDT</span>,
    },
    {
      title: "Total Rating",
      align: "center",
      render: (val: GetAllUsersData) => (
        <span>{fixedNumber(val?.rating / val?.numberOfTask)}</span>
      ),
    },
    {
      title: "Created Date",
      align: "center",
      dataIndex: "createdAt",
      render: (val) => <span>{formatDate(val)}</span>,
    },
    // {
    //   title: "Payment Status",
    //   align: "center",
    //   render: (val: GetAllUsersData) => <span>-</span>,
    // },

    // {
    //   title: "Action",
    //   align: "center",
    //   render: (val: GetAllUsersData) => (
    //     <Button
    //       type="primary"
    //       size="large"
    //       onClick={() =>
    //         handleOpen({
    //           type: "VIEW",
    //           data: val,
    //         })
    //       }
    //     >
    //       View
    //     </Button>
    //   ),
    // },
  ];

  useEffect(() => {
    const params: GetAllUsersParams = {
      limit: limit,
      skip: limit * (page - 1),
      isPG: false,
      role: "finance",
    };

    const searchParams: GetAllUsersParams = {
      isPG: false,
      lastName: keyword,
      role: "finance",
    };

    if (path === "vendor") {
      params.suppler = login?.suppler;
    }

    dispatch(
      getAllUsersAsync({
        params: keyword ? searchParams : params,
      })
    );
  }, [dispatch, keyword, limit, login?.suppler, page, path]);

  useEffect(() => {
    const params: any = {
      isPG: false,
      role: "finance",
      lastName: keyword,
    };

    if (path === "vendor") {
      params.suppler = login?.suppler;
    }

    dispatch(
      getCountUsersAsync({
        params,
      })
    );
  }, [dispatch, keyword, login?.suppler, path]);

  useEffect(() => {
    if (keyword && isFirstPage) {
      handlePagination(1, 10);
      handleSetFirstPage(false);
    }
  }, [handlePagination, handleSetFirstPage, isFirstPage, keyword]);

  return (
    <>
      {/* {open.type === "VIEW" && (
        <ViewResource
          title="Resources Information Log"
          open={open.open}
          handleClose={toggle}
          iconColor="bg-status-waiting-btn"
          locatorTitle="Shilmoon West Para - Mymensingh"
          data={open?.data}
        />
      )} */}
      <CustomTable<TableData<GetAllUsersData>>
        loading={loading}
        total={usersCount}
        page={page}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(allUsers)}
        isPagination={keyword ? false : true}
      />
    </>
  );
}
