import { DatePicker, Select, Space } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import PaymentTable from "../../../components/NP/Payment/PaymentTable";
import Subheader from "../../../components/ui/Subheader";
import { selectSearchOption } from "../../../helpers/siteConfig";
import AdminLayout from "../../../layouts/AdminLayout";
import { useAppSelector } from "../../../store/store";
import { AddZero } from "../../../utils/number";

export default function Payment() {
  const { paymentCount } = useAppSelector((state) => state.payment);
  const [checked, setChecked] = useState<"all" | "no">("all");
  const [status, setStatus] = useState(undefined);
  const [date, setDate] = useState({
    from: "",
    to: "",
  });

  return (
    <AdminLayout title="Payment Summary" headerTitle="Payment">
      <section className="shadow-sm mt-7">
        <Subheader
          title="Payment Summary"
          subTitle={`Total Payment: ${AddZero(paymentCount)}`}
        >
          <Space wrap size="small">
            <DatePicker.RangePicker
              allowClear
              format="YYYY-MM-DD"
              className="date-range"
              value={[
                date?.from ? dayjs(date?.from, "YYYY-MM-DD") : null,
                date?.to ? dayjs(date?.to, "YYYY-MM-DD") : null,
              ]}
              onChange={(val) => {
                setDate({
                  from: val?.[0]?.format("YYYY-MM-DD") || "",
                  to: val?.[1]?.format("YYYY-MM-DD") || "",
                });
              }}
            />
            <Select
              placeholder="Checked"
              onChange={(val) => setChecked(val)}
              filterOption={selectSearchOption}
              className="min-w-[8rem]"
              options={[
                {
                  value: "all",
                  label: "Checked",
                },
                {
                  value: "no",
                  label: "Unchecked",
                },
              ]}
            />

            <Select
              allowClear
              placeholder="Status"
              onChange={(val) => setStatus(val)}
              filterOption={selectSearchOption}
              className="min-w-[8rem]"
              options={[
                {
                  value: "true",
                  label: "Paid",
                },
                {
                  value: "false",
                  label: "Unpaid",
                },
              ]}
            />
          </Space>
        </Subheader>

        <PaymentTable checked={checked} status={status} date={date} />
      </section>
    </AdminLayout>
  );
}
